import React from "react";
import Content from "./Content";

const Events = () => {
  return (
    <Content>
      <h2>Events and workshops</h2>
      <p>For details about my upcoming workshops, please see my Instagram page <a href={'https://www.instagram.com/kath_priestley_designs/'}>@kath_priestley_designs</a></p>
      <h3>November 2024</h3>
      <p>
        Southwell Minster Winter Craft Fair - Friday 8th November 2024, 4–8pm and Saturday 9th November 2024 10am–3.30pm
      </p>
      <p>
        The Garage Chilwell - Sunday 24th November 2024, 10am–2pm
      </p>
      <p>
        Dronfield Hall Barn Artisan Market and Craft Fair - Friday 29th November 2025, 4-7.30pm and Saturday 30th November 2024, 10am - 3pm
      </p>
      <h3>December 2023</h3>
      <p>
        Ruddington Village Christmas Market - Friday 1st and Saturday 2nd
        December
      </p>
      <h3>November 2023</h3>
      <p>Southwell Minster Winter Craft Fair - Friday 3rd and Saturday 4th</p>
      <p>
        November Dronfield Hall Barn Artisan Market and Craft Fair - Friday 24th
        and Saturday 25th November
      </p>
      <h3>October 2023</h3>
      <p>
        Dronfield Hall Barn Artisan Market and Craft Fair - Sunday 8th October
      </p>
      <p>Helmsley Craft Fair - Sunday 15th October</p>
    </Content>
  );
};

export default Events;
